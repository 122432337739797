

export const headers = [
  {
    text:"№",
    value:"index",
    flex:1,
    width:60
  },
  {
    text:"Код товара",
    value:"tn_ved",
    flex:1,
    width:140
  },
  {
    text:"Наименование товара",
    value:"title",
    flex:18,
    width:500

  },
  {
    text:"Кол-во мест",
    value:"cargo_place_quantity",
    flex:1,
    width:40
  },
  {
    text:"Вид упаковки",
    value:"package_type_code",
    flex:1,
    width:40
  },
  {
    text:"Происх.",
    value:"origin_country_letter",
    flex:1,
    width:50
  },
  {
    text:"Нетто",
    value:"net_weight",
    flex:1,
    width:100
  },
  {
    text:"Брутто",
    value:"gross_weight",
    flex:1,
    width:100
  },
  {
    text:"Стоимость",
    value:"cost",
    flex:4,
    width:100
  },
  {
    text:"Дата отгрузки",
    value:"shipment_date",
    flex:1,
    width:110
  }
]